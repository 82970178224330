import React from 'react'
import img from '../../images/laser-cialo.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import {Fade} from 'react-reveal'
import {Seo} from '../../components/Seo'

const EpilacjaLaserowaCialo = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='Epilacja Laserowa | Modelowanie Sylwetki'
                    description='Epilacja laserowa jest obecnie najskuteczniejszą metodą trwałego
          usuwania owłosienia ze wszystkich okolic ciała. Zabieg wykonywany przy
          użyciu naszego trójwiązkowego lasera jest komfortowy, szybki, prawie
          bezbolesny i działa zarówno na ciemne, jak i jasne włosy.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-cialo/epilacja-laserowa'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>Epilacja Laserowa</h1>
                            <div className='section'>
                                <div className='image-container'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='Epilacja laserowa zdjęcie'
                                    ></img>
                                </div>
                                <div className='text'>
                                    <p>
                                        Epilacja laserowa jest obecnie najskuteczniejszą
                                        metodą trwałego usuwania owłosienia ze wszystkich
                                        okolic ciała. Zabieg wykonywany przy użyciu
                                        naszego trójwiązkowego lasera jest komfortowy,
                                        szybki, prawie bezbolesny i działa zarówno na
                                        ciemne, jak i jasne włosy. Nasz laser należy do
                                        najmocniejszych laserów do epilacji na rynku nie
                                        tylko w Polsce, ale w całej Europie. Jest laserem
                                        medycznym, diodowym zawierającym trzy wiązki
                                        działające w jednej głowicy. Zabiegi laserem z
                                        trzema wiązkami można wykonywać przez cały rok w
                                        obrębie całego ciała.
                                    </p>

                                    <p>
                                        Z zabiegu epilacji laserowej korzystają zarówno
                                        kobiety jak i mężczyźni. Osoby chętnie pozbywają
                                        się zbędnego owłosienia nie tylko ze względów
                                        estetycznych czy higienicznych, ale również ze
                                        względów zdrowotnych. Ponadto z epilacji rąk czy
                                        nóg coraz częściej korzystają sportowcy: kolarze,
                                        biegacze czy pływacy, którzy dzięki pozbyciu się
                                        owłosienia uzyskują lepsze sportowe wyniki. Po
                                        wykonaniu serii 4-6 zabiegów w odstępach min. 4-6
                                        tygodniowych, po 18 miesiącach należy wykonać
                                        zabieg przypominający, który utrwali spektakularne
                                        rezultaty. Według naszych Klientów epilacja
                                        laserowa to ogromny komfort i wygoda, oszczędność
                                        czasu a przede wszystkim oszczędność pieniędzy.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
            <div className='copyright-notice'>
                <a href='https://www.freepik.com/free-photo/cropped-view-fit-woman-shows-her-belly-has-slim-body_10547586.htm'>
                    Image by wayhomestudio
                </a>{' '}
                <div>on Freepik</div>
            </div>
        </>
    )
}

export default EpilacjaLaserowaCialo
